import React, {Component} from 'react';
import IconTooltip from "./Components/IconTooltip";
import {Button, Checkbox, Col, FormGroup, Modal, Row} from "react-bootstrap";
import axios from "axios/index";
import SimpleTable from "./Components/SimpleTable";
import LabeledInput from "./Components/LabeledInput";
import Select from "./Components/Select";

class Detail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: props.error ? props.error : null,
            isLoaded: props.isLoaded,
            person: props.data ? props.data.person : null,
            lib_states: props.data ? props.data.lib_states : null,
            countries: props.data ? props.data.countries : null,
            index: props.data ? props.data.index : null,
            recModalShow: false,
            recommended: props.data ? props.data.recommended : null,
            editModal: false,
            newPerson: {},
            byvanie: [],
            rodstav: []
        };


    }

    componentDidMount() {

        axios.get(window.localStorage.getItem('url') + "/CallcentrumQueueServlet?token=" + window.localStorage.getItem('token') + "&action=EASYLIB&el_id=2")
            .then(response => {
                let byvanie = response.data;

                this.setState({
                    byvanie
                })
            }).catch(error => {
            console.trace(error);
        });
        axios.get(window.localStorage.getItem('url') + "/CallcentrumQueueServlet?token=" + window.localStorage.getItem('token') + "&action=EASYLIB&el_id=18")
            .then(response => {
                let rodstav = response.data;

                this.setState({
                    rodstav
                })
            }).catch(error => {
            console.trace(error);
        });

    }

    componentWillReceiveProps(props) {
        this.setState(
            {
                isLoaded: props.isLoaded,
                person: props.data.person,
                lib_states: props.data.lib_states,
                countries: props.data.countries,
                index: props.data.index,
                error: props.error,
                recommended: props.data.recommended
            }
        );
    }

    showRecomended = () => {
        this.setState({recModalShow: true});
    };

    hideRecomended = () => {
        this.setState({recModalShow: false});
    };

    showEdit = () => {
        let newPerson = JSON.parse(JSON.stringify(this.state.person));
        this.setState({newPerson, editModal: true});
    };

    hideEdit = () => {
        this.setState({editModal: false});
    };

    handleEdit = (input, event) => {
        let newPerson = this.state.newPerson;
        newPerson[input] = event.target.value;
        this.setState({newPerson});
    };

    confirmEdit = () => {
        console.log(this.state.person);
        console.log(this.state.newPerson);
        this.setState({person: this.state.newPerson},
            () => {
                this.hideEdit();
                this.props.onEdit(this.state.person);
                axios.post(window.localStorage.getItem('url') + '/CallcentrumQueueServlet?token=' + window.localStorage.getItem('token') + "&edit=true", {...this.state.person, index: this.state.index})
                    .then()
                    .catch(err => {
                        console.log(err);
                    })
            });
    };


    setIndex = (parent, event) => {
        let {index} = this.state;
        let {target} = event;
        if(parent === null) {
            index[target.id].set = target.checked;
        } else {
            index[parent].children[target.id].set = target.checked;
        }
        this.setState({index});
    };

    changeByvanie = (selected) => {
        this.handleEdit("housing", {target:{value:selected}})
    };

    changeRodstav = (selected) => {
        this.handleEdit("state", {target:{value:selected}})
    };

    render() {
        const {error, isLoaded, person, recommended} = this.state;
        if (error) {
            let err_message = '';
            if (error.message) {
                if (error.message.response) {
                    err_message = error.message.response;
                } else if (error.message.includes('person_id')) {
                    err_message = 'Prázdna fronta';
                }
            }
            return <div>Chyba: {err_message}</div>;
        } else if (!isLoaded) {
            return <div className="theme_tail" style={{"width": "320px", "margin": "50px auto 20px auto", "position": "relative"}}>
                <div className="pace_activity"/>
            </div>
                ;
        } else {
            let editButton;
            if (this.state.person.edited) {
                editButton = (<IconTooltip classes={"icon-quill2 position-left"} id="edit" tooltip={"Kontakt už obsahuje nepotvrdené zmeny"}
                                           data={"Upraviť"}/>)
            } else {
                editButton =
                    (<button className="btn btn-flat btn-icon bordered-btn" onClick={this.showEdit} disabled={this.state.person.edited}>
                        <i className="icon-quill2 position-left"/>Upraviť
                    </button>);
            }
            return (
                <div>
                    <div className="row">
                        <div className="col-md-3 avatar">
                            <img className="m-10" src={"/assets/images/avatars/" + person.gender + ".png"}
                                 alt="avatar"/>
                        </div>
                        <div className="col-md-7">
                            <h1 className="mt-5 text-bold">
                                {person.firstname} {person.surname} (<i className="icon-gift position-left"/>{person.birthyear})
                            </h1>

                            <div className="text-semibold text-size-xlarge">
                                <span>
                                    {person.street}<br/>
                                    {person.zip}, {person.city}<br/>
                                    {person.country}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-2">
                            {editButton}
                            <Modal
                                show={this.state.editModal}
                                onHide={this.hideEdit.bind(this)}
                                bsSize={"lg"}
                            >
                                <Modal.Header className="bg-success">
                                    <Modal.Title>Úprava</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <FormGroup>
                                                <Row>
                                                    <Col md={6}>
                                                        <LabeledInput
                                                            placeholder={"Meno"}
                                                            onChange={this.handleEdit.bind(this, "firstname")}
                                                            value={this.state.newPerson.firstname || ''}
                                                        />
                                                    </Col>
                                                    <Col md={6}>
                                                        <LabeledInput
                                                            placeholder={"Priezvisko"}
                                                            onChange={this.handleEdit.bind(this, "surname")}
                                                            value={this.state.newPerson.surname || ''}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <LabeledInput
                                                            placeholder={"Ulica"}
                                                            onChange={this.handleEdit.bind(this, "street")}
                                                            value={this.state.newPerson.street || ''}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <LabeledInput
                                                            placeholder={"PSC"}
                                                            onChange={this.handleEdit.bind(this, "zip")}
                                                            value={this.state.newPerson.zip || ''}
                                                        />
                                                    </Col>
                                                    <Col md={6}>
                                                        <LabeledInput
                                                            placeholder={"Mesto"}
                                                            onChange={this.handleEdit.bind(this, "city")}
                                                            value={this.state.newPerson.city || ''}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <LabeledInput
                                                            placeholder={"Krajina"}
                                                            onChange={this.handleEdit.bind(this, "country")}
                                                            value={this.state.newPerson.country || ''}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <LabeledInput
                                                            placeholder={"Telefón"}
                                                            onChange={this.handleEdit.bind(this, "mobile")}
                                                            value={this.state.newPerson.mobile || ''}
                                                        />
                                                    </Col>
                                                    <Col md={6}>
                                                        <LabeledInput
                                                            placeholder={"E-mail"}
                                                            onChange={this.handleEdit.bind(this, "email")}
                                                            value={this.state.newPerson.email || ''}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <LabeledInput
                                                            placeholder={"Rok narodenia"}
                                                            onChange={this.handleEdit.bind(this, "birthyear")}
                                                            value={this.state.newPerson.birthyear || ''}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <Select
                                                            label={"Bývanie"}
                                                            options={this.state.byvanie.map(el => {
                                                                return {id: el.easylib_name, value:el.easylib_name}
                                                            })}
                                                            value={this.state.newPerson.housing}
                                                            onChange={this.changeByvanie.bind(this)}
                                                        />
                                                    </Col>
                                                    <Col md={6}>
                                                        <Select
                                                            label={"Rodinný stav"}
                                                            options={this.state.rodstav.map(el => {
                                                                return {id: el.easylib_name, value:el.easylib_name}
                                                            })}
                                                            value={this.state.newPerson.state}
                                                            onChange={this.changeRodstav.bind(this)}
                                                        />
                                                    </Col>
                                                </Row>

                                        { window.localStorage.getItem("aktiv") === "true" || true ? ( //toto mal povodne vidiet iba aktiv no chceli nech to vidia vsetci. nechavam tu ale podmienku do buducna, moze sa zist
                                            <React.Fragment>

                                                <FormGroup style={{
                                                    maxHeight: "400px",
                                                    overflow: "auto",
                                                    marginTop: "3px"}}>
                                                    {this.state.index.map((item, index) => (
                                                        <React.Fragment key={'cat' + index}>
                                                        <Row>
                                                            <Col md={12} style={{borderTop: "1px solid black"}}>
                                                                <Checkbox id={index} key={item.id} label={item.name} checked={Boolean(item.set) === true} position="right" onChange={this.setIndex.bind(this, null)} style={item.name === 'NEKONTAKTOVAŤ' ? {color: 'red'} : {}} disabled={item.id === -1}><b>{item.name}</b></Checkbox>

                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            {item.children.map((child, indexChild) => (
                                                                <Col key={'child' + indexChild} md={4}>
                                                                    <Checkbox id={indexChild} key={child.id} label={child.name} checked={Boolean(child.set) === true} position="right" onChange={this.setIndex.bind(this, index)} style={child.name === 'NEKONTAKTOVAŤ' ? {color: 'red'} : {}}>{child.name}</Checkbox>

                                                                </Col>
                                                            ))}
                                                        </Row>
                                                        </React.Fragment>
                                                    ))}
                                                </FormGroup>
                                            </React.Fragment>
                                        ) : null}

                                    </FormGroup>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={this.hideEdit.bind(this)}>Zrušiť</Button>
                                    <Button className="btn-success" onClick={this.confirmEdit}>Odoslať</Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                    <div className="row mt-15">
                        <div className="col-md-3 text-semibold text-size-large">
                            <h4>Detaily</h4>
                            <IconTooltip classes={"icon-mobile position-left"} id="number" tooltip={"Tel. číslo"}
                                         data={person.mobile}/>
                            <IconTooltip classes={"icon-mail5 position-left"} id={"email"} tooltip={"E-mail"}
                                         data={person.email}/>
                            <IconTooltip classes={"icon-database2 position-left"} id={"source"} tooltip={"Zdroj"}
                                         data={person.source}/>
                            <IconTooltip classes={"icon-home2 position-left"} id={"housing"} tooltip={"Bývanie"}
                                         data={person.housing}/>


                        </div>
                        <div className="col-md-3 text-semibold text-size-large">
                            <h4 className="hoverable" onClick={this.showRecomended}>Odporučil</h4>

                            <Modal
                                show={this.state.recModalShow}
                                onHide={this.hideRecomended}
                            >
                                <Modal.Header className="bg-primary">
                                    <Modal.Title>Odporučil</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <SimpleTable content={recommended}/>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button className="btn btn-primary" onClick={this.hideRecomended}>Zatvoriť</Button>
                                </Modal.Footer>
                            </Modal>

                            <IconTooltip classes={"icon-users position-left"} id={"commendatory"}
                                         tooltip={"Kto odporučil"}
                                         data={person.commendatory}/>
                            <IconTooltip classes={"icon-link position-left"} id={"relationship"} tooltip={"Vzťah"}
                                         data={person.relationship}/>
                            <IconTooltip classes={"icon-bowtie position-left"} id={"state"} tooltip={"Rodinný stav"}
                                         data={person.state}/>
                            <IconTooltip classes={"icon-calendar position-left"} id={"housing"} tooltip={"Odporučený dňa"}
                                         data={person.recommendedDate}/>

                        </div>

                        <div className="col-md-6 text-semibold text-size-large">
                            <h4>Spracovanie osobných údajov</h4>
                            <div className="row">
                                <div className="col-md-8">Súhlas so spracovaním osobných udajov:</div>
                                <div className="col-md-4">{person.agreement === true ? "áno" : (
                                    <span style={{color: "red"}}>nie</span>)}</div>
                            </div>
                            <div className="row">
                                <div className="col-md-8">Posledný dátum udelenia súhlasu:</div>
                                <div className="col-md-4">{person.date}</div>
                            </div>
                            <div className="row">
                                <div className="col-md-8">Platnosť súhlasu:</div>
                                <div className="col-md-4">{person.valid}</div>
                            </div>
                        </div>

                    </div>


                </div>
            );
        }
    }
}

export default Detail;
