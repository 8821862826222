import React, {useEffect, useMemo, useState} from "react";
import MaterialReactTable from "material-react-table";
import {MRT_Localization_SK} from "material-react-table/locales/sk";
import dateFormat from "dateformat";



function OperatorQueueTable(props) {

    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {

        let data=[]
        props.content.body.forEach((element=>{
            let name =element.columns[0].value.replace(element.rowid,'')
            let city =element.columns[1].value
            let district =element.columns[2].value
            let type =element.columns[3].value
            let recommend =element.columns[4].value
            let time =element.columns[5].value
            let number =element.rowid
            let css =element.css
            data.push({name, city, district, type, recommend, time, number, css})
        }))
        setData(data)
        setLoading(false)
    }, [props]);

    function isValidDate(d) {
        return d instanceof Date && !isNaN(d);
    }

    let columns = useMemo(
        () => [
            {
                accessorKey: 'name',
                header: "Meno",
            },
            {
                accessorKey: 'number',
                header: "Číslo",
            },
            {
                accessorKey: 'city',
                header: "Mesto",
            },
            {
                accessorKey: 'district',
                header: "Okres",
            },
            {
                accessorKey: 'type',
                header: "Typ ukážky",
            },
            {
                accessorKey: 'recommend',
                header: "Odporučil",
            },
            {
                accessorFn: (originalRow) => !isValidDate(new Date(originalRow.time)) ? "" : new Date(originalRow.time).toLocaleString(),
                id: "time",
                header: "Čas",
                filterVariant: 'text',
                sortingFn: (rowA, rowB, columnId) => {
                    if (rowA.original.time === "") {
                        return 1
                    }
                    if (rowA.original.time > rowB.original.time) {
                        return 1
                    }
                    if (rowA.original.time > rowB.original.time) {
                        return -1
                    }
                    return 0
                },
                Cell: ({cell}) => cell.getValue()
            },
        ],
            [],
    );


    return (
        <div className={"offeris-data-table"}>
            <MaterialReactTable
                enableColumnActions={false}
                enableSelectAll={false}
                localization={MRT_Localization_SK}
                enableStickyHeader
                columns={columns}
                data={data}
                muiTableBodyRowProps={({ row }) => ({
                    //implement row selection click events manually
                    onClick: () => props.rowClick(row.original.number),
                    sx: {
                        cursor: 'pointer',...row.original.css
                    },
                })}
                enableRowVirtualization
                muiTableContainerProps={{ sx: { height: 'clamp(300px, 300px, 9999px)' } }}
                enableRowSelection={false}
                enableGlobalFilter={true}
                enableColumnFilters={true}
                //enableHiding={false}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                muiTablePaginationProps={{
                    rowsPerPageOptions: [50, 100, 500]
                }}
                initialState={{
                    showGlobalFilter: true,
                    density: 'compact',
                    sorting: [{id: 'time', desc: false}],
                    pagination: { pageSize: 100,pageIndex:0}
                }}
            />
        </div>)


}


export default OperatorQueueTable;




